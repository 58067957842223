import React from 'react'
import { graphql, Link, navigate } from "gatsby"
import {GatsbyImage} from 'gatsby-plugin-image'
import { PortableText } from "@portabletext/react"
import { ArrowUUpLeft } from "phosphor-react"
import Serializers from '../components/serializers'
import { css } from '@emotion/react'
import styled from "@emotion/styled"

import Layout from '../components/layout'
import { Seo } from "../components/seo"

const _ = require("lodash")

const Grid = styled.ul`
display: grid;
grid-template-columns: 1fr 1fr 1fr;
grid-gap: 2.5vw;
@media (max-width: 599px) {
  grid-template-columns: 42.5vw 42.5vw;
  grid-gap: 5vw;
}
a {
  color: rgba(0,0,0,0.75);
  transition: all 0.3s ease;
  &:hover {
    color: red;
  }
}
li {
  margin-bottom: 5vh;
}
img {
  width: 17.5vw;
  height: 17.5vw;
  @media (max-width: 599px) {
    width: 42.5vw;
    height: 42.5vw;
  }
}
h4 {
  margin-bottom: 0;
  margin-top: 2vh;
  padding: 0;
  font-size: 1rem;
  width: 100%;
  font-family: courier;
  box-sizing: border-box;
  text-transform: uppercase;
  line-height: 1.25em;
}
`
const Close = styled.div`
position: absolute;
z-index: 10;
right: 12.5vw;
top: 15vh;
padding: 5px 7px;
background: rgba(0,0,0,0.05);
transition: all 0.3s ease;
&:hover {
  cursor: pointer;
  background: rgba(0,0,0,0.10);
}
`

export const query = graphql`
  query PageQuery($id: String!) {
    page: sanityPage(id: { eq: $id }) {
      _id
      subTitle
      title
      _rawBody(resolveReferences: {maxDepth: 5})
      ledeImage {
        asset {
          gatsbyImageData
        }
      }
      page {
        _id
        title
        subTitle
        ledeImage {
          asset {
            gatsbyImageData
            url
          }
        }
      }
    }
  }
`
const Description = styled.div`
padding-right: 0 !important;
width: 100% !important;
font-weight: 400;
line-height: 1.5em;
margin-top: 1vh;
text-transform: uppercase;
font-size: .875rem;
`

const PageTemplate = ({ data }) => {
    return (
        <Layout>
        <Close><ArrowUUpLeft size={32} onClick={ () => navigate(-1)}/></Close>
        <h1>{data.page.title}</h1>
        {data.page.subTitle && <h4>{data.page.subTitle}</h4>}
        {data.page.ledeImage &&
        <>
        <GatsbyImage image={data.page.ledeImage.asset.gatsbyImageData}  alt={data.page.title}/>
        </>
        }
        <PortableText
          value={data.page._rawBody}
          components={Serializers}
        />
        <div css={{clear: 'both'}}></div>
        {data.page.page.length !== 0 &&
        <Grid>
        {data.page.page.map(item =>
          <li key={item._id}><Link to={`/work/${_.kebabCase(data.page.title)}/${_.kebabCase(item.title)}`} asModal>{item.ledeImage.asset && <GatsbyImage image={item.ledeImage.asset.gatsbyImageData} objectFit="cover" alt={item.title} />}<h4>{item.title}</h4><Description>{item.subTitle}</Description></Link></li>
        )
        }
        </Grid>
        }
        </Layout>
    )
}

export default PageTemplate

export const Head = ({ data }) => (
  <Seo pageTitle={data.page.title}/>
)