import React from 'react'
import { PortableText } from "@portabletext/react"
import styled from "@emotion/styled"

const Quotewrap = styled.blockquote`
border-left: solid 1px #000;
padding-left: 4vw;
margin: 5vh 0;
width: 90%;
cite {
    font-style: italic;
    font-size: .9125rem;
}
`

const Quote = ({value}) => {
    return (
        <Quotewrap><PortableText value={value.quote} /><cite>{value.author}</cite></Quotewrap>
    )
}
export default Quote