import React from 'react'
import ImageComponent from "./image"
import SidebarComponent from "./sidebar"
import BlockLinks from "./blocklinks"
import Quote from "./quote"
import Url from "./url"

const Serialzers = {
  types: {
    button: Url,
    figure: ImageComponent,
    sidebar: SidebarComponent,
    blocklinks: BlockLinks,
    quote: Quote,
  },
    marks: {
        link: ({children, value}) => {
            const rel = !value.href.startsWith('/') ? 'noreferrer noopener' : undefined
            return (
              <a href={value.href} rel={rel}>
                {children}
              </a>
            )
        },
    },
}

export default Serialzers