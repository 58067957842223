import React from 'react'
import styled from "@emotion/styled"

const Listwrap = styled.ul`
float: left;
width: 27.5% !important;
padding-bottom: 0 !important;
a {
    text-transform: none !important;
    letter-spacing: 0px !important;
}
li {
    line-height: 1.33rem;
    margin-bottom: 2.5vh;
    margin-right: 2vw;
}
`

const SidebarComponent = ({value}) => {
    const list = value.sidebarList
    return (
        <Listwrap>
        {list.map(item => (
            <li key={item._key}><a href={item.link} rel="noreferrer" target="_blank">{item.title}</a></li>
        ))}
        </Listwrap>
    )
}

export default SidebarComponent