import React from 'react'
import { Link } from "gatsby"
import {GatsbyImage} from 'gatsby-plugin-image'
import {getGatsbyImageData} from 'gatsby-source-sanity'
import styled from "@emotion/styled"

const Cardwrap = styled.ul`
display: grid;
grid-template-columns: 1fr 1fr 1fr;
grid-gap: 2.5vw;
padding-bottom: 0 !important;
padding-top: 0 !important;
background: transparent !important;
@media (max-width: 599px) {
  grid-template-columns: 1fr 1fr;
}
a {
  color: rgba(0,0,0,0.75);
  display: block;
  height: 100%;
  text-decoration: none;
  border-bottom: 0 !important;
  transition: all 0.3s ease;
  img {
      transition: all 0.3s ease;
  }
  &:hover {
    color: red;
    background: #fff;
    img {
        opacity: .75 !important;
    }
  }
}
li {
  margin-bottom: 2.5vh;
}
img {
  object-fit: cover;
  @media (max-width: 599px) {
    width: 40vw;
    height: 40vw;
  }
}
h4 {
  margin-bottom: 0;
  margin-top: 2vh;
  padding: 0;
  font-size: 1rem;
  width: 100%;
}
`
const _ = require("lodash")

const BlockLinks = ({value}) => {
    const list = value.blocklink

    const sanityConfig = {projectId: process.env.SANITY_PROJECT_ID, dataset: process.env.SANITY_DATASET}
    const imageAlt = value.blocklink.caption ? value.blocklink.caption : 'an image'

    return (
        <Cardwrap>
        {list.map(item => 
            <li key={item._key}>
                <Link to={`/${_.kebabCase(item.title)}`}>
                <GatsbyImage image={getGatsbyImageData(item.image.asset._id, {placehomder: "blurred"}, sanityConfig)} alt={imageAlt} objectFit='contain' />
                <h4>{item.title}</h4>
                </Link>
            </li>
        )}
        </Cardwrap>
    )
}

export default BlockLinks