import React from 'react'
import {GatsbyImage} from 'gatsby-plugin-image'
import {getGatsbyImageData} from 'gatsby-source-sanity'

// Barebones lazy-loaded image component
const ImageComponent = ({value}) => {
  const sanityConfig = {projectId: process.env.SANITY_PROJECT_ID, dataset: process.env.SANITY_DATASET}
  const imageAssetId = value.image.asset._id
  const imageData = getGatsbyImageData(imageAssetId, {placehomder: "blurred"}, sanityConfig)
  const imageAlt = value.caption ? value.caption : 'an image'

  return (
    <figure>
      <GatsbyImage image={imageData} alt={imageAlt} objectFit='contain' />
      {value.caption && <figcaption><span>{value.caption}</span></figcaption>}
    </figure>
  )
}
export default ImageComponent